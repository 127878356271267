import React from "react";
import { useRecoilState } from "recoil";
import Div100vh from "react-div-100vh";
import { Scrollbars } from "react-custom-scrollbars";
import Script from "react-load-script";
import {
  Box,
  Paper,
  AppBar,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  Drawer,
  ButtonBase,
  Divider,
  useMediaQuery,
} from "@mui/material";
import {
  DashboardIcon,
  ConfiguratorIcon,
  ExperimentsIcon,
  ReportingIcon,
  SendEstimateIcon,
  KnowledgeIcon,
  PaymentsIcon,
  CreditsIcon,
  ClientsIcon,
  TeamIcon,
  ProfileIcon,
  ArrowLeftIcon,
  MenuIcon,
  FeatureRequestIcon,
  DemandIQLogo,
  IntegrationsIcon,
  MegaphoneIcon,
} from "utils/icons";
import { loadingAtom } from "store/common";
import { apiGetClientHubspotVisitorIdentificationToken } from "store/api";
import { clientAtom } from "store/client";
import useAlert from "hooks/useAlert";
import CompanyMenu from "./CompanyMenu";
import NavItem from "./NavItem";
import UserMenu from "./UserMenu";
import {
  isSuper,
  isAdminOrSuper,
  isUserOrAdminOrSuper,
  isRepOrUserOrAdminOrSuper,
  isAnalystOrUserOrAdminOrSuper,
  isAnyUser,
} from "utils/authHelper";
import { authAtom } from "store/auth";
import useUI from "hooks/useUI";
import AnnounceKit from "announcekit-react";

const navList = [
  { url: "/dashboard", label: "DASHBOARD", icon: <DashboardIcon />, auth: isAnyUser },
  { group: "PRODUCTS", auth: isAnyUser },
  { url: "/products/configurator", label: "Configurator", icon: <ConfiguratorIcon />, auth: isUserOrAdminOrSuper },
  { url: "/products/experiments", label: "Experiments", icon: <ExperimentsIcon />, auth: isSuper },
  { url: "/products/reporting", label: "Reporting", icon: <ReportingIcon />, auth: isAnalystOrUserOrAdminOrSuper },
  {
    url: "/products/integrations",
    label: "Workflows",
    icon: <IntegrationsIcon />,
    auth: isRepOrUserOrAdminOrSuper,
  },
  {
    url: "/products/instant-estimate",
    label: "Send Estimate",
    icon: <SendEstimateIcon />,
    auth: isRepOrUserOrAdminOrSuper,
  },
  {
    extUrl: "https://content.demand-iq.com",
    label: "Ad Library",
    icon: <MegaphoneIcon />,
    auth: isAnyUser,
  },
  {
    extUrl: "https://knowledge.demand-iq.com",
    label: "Knowledge Base",
    icon: <KnowledgeIcon />,
    auth: isAnyUser,
  },
  {
    noltUrl: "https://demandiq.nolt.io",
    label: "Feature Requests",
    icon: <FeatureRequestIcon />,
    auth: isAnyUser,
  },

  { group: "BILLING", auth: isUserOrAdminOrSuper },
  { url: "/billing/payments", label: "Payments", icon: <PaymentsIcon />, auth: isUserOrAdminOrSuper },
  { url: "/billing/earn-rewards", label: "Earn Free Credits", icon: <CreditsIcon />, auth: isUserOrAdminOrSuper },
  { group: "SETTINGS", auth: isAnyUser },
  { url: "/settings/clients", label: "Clients", icon: <ClientsIcon />, auth: isSuper },
  { url: "/settings/team", label: "Team", icon: <TeamIcon />, auth: isAdminOrSuper },
  { url: "/settings/profile", label: "My Profile", icon: <ProfileIcon />, auth: isAnyUser },
];

const Layout2 = ({ children }) => {
  const hUI = useUI();
  const hAlert = useAlert();
  const [clientState] = useRecoilState(clientAtom);
  const [loading] = useRecoilState(loadingAtom);
  const [authState] = useRecoilState(authAtom);

  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [noltStatus, setNoltStatus] = React.useState(false);

  React.useEffect(() => {
    const loadHubspotWidget = async () => {
      try {
        const res = await apiGetClientHubspotVisitorIdentificationToken(clientState.currentId);

        if (res.status === 200 && res.data["token"]) {
          window.hsConversationsSettings = {
            identificationEmail: clientState.current.email,
            identificationToken: res.data["token"],
          };
          window.HubSpotConversations.widget.load();
        }
      } catch (error) {
        console.log(error);
      }
    };
    loadHubspotWidget();
  }, []);

  React.useEffect(() => {
    hUI.hideMobileSidebar();
  }, [mdUp]);

  React.useEffect(() => {}, []);

  const handleScriptLoad = () => {
    const { nolt } = window;

    const jwtNolt = window.localStorage.getItem("jwt_nolt");

    nolt("identify", { jwt: jwtNolt });

    setNoltStatus(true);
  };

  return (
    <Div100vh>
      <Box sx={{ width: 1, height: 1, display: "flex" }}>
        <Paper
          elevation={2}
          square
          sx={{
            display: { xs: "none", md: "block" },
            width: 260,
            height: 1,
            backgroundColor: "primary.main",
            color: "#fff",
            zIndex: 10,
          }}
        >
          <Box
            sx={{
              px: 4,
              height: 64,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              color: "white",
            }}
          >
            <DemandIQLogo style={{ maxWidth: 196 }} />
          </Box>

          <Box sx={{ width: "100%", height: "calc(100% - 64px)" }}>
            <Scrollbars autoHide>
              <CompanyMenu />

              <Box mt={4}>
                {navList.map((data, index) => (
                  <NavItem key={index} data={data} enabled={data.noltUrl ? noltStatus : true} />
                ))}
              </Box>
            </Scrollbars>
          </Box>
        </Paper>

        <Drawer
          container={window !== undefined ? () => window.document.body : undefined}
          variant="temporary"
          open={hUI.openMobileSidebar}
          onClose={hUI.hideMobileSidebar}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: 260 },
          }}
        >
          <Paper
            elevation={2}
            square
            sx={{
              display: { xs: "block", md: "none" },
              width: 260,
              height: 1,
              backgroundColor: "primary.main",
              color: "#fff",
              zIndex: 10,
            }}
          >
            <Box
              sx={{
                px: 2,
                height: 64,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                columnGap: 1,
                color: "white",
              }}
            >
              <DemandIQLogo style={{ maxWidth: 196 }} />

              <ButtonBase onClick={() => hUI.hideMobileSidebar()}>
                <ArrowLeftIcon style={{ fontSize: 24 }} />
              </ButtonBase>
            </Box>

            <Box sx={{ width: "100%", height: "calc(100% - 64px)" }}>
              <Scrollbars autoHide>
                <CompanyMenu />

                <Box mt={4}>
                  {navList.map((data, index) => (
                    <NavItem key={index} data={data} />
                  ))}
                </Box>
              </Scrollbars>
            </Box>
          </Paper>
        </Drawer>

        <Box
          sx={{
            flexGrow: 1,
            height: 1,
          }}
        >
          <AppBar
            color="inherit"
            position="relative"
            sx={{ height: 64, flexDirection: "row", zIndex: 5, paddingRight: 2 }}
          >
            <Box sx={{ flex: 1, height: 1, display: "flex", alignItems: "center" }}>
              {!mdUp && (
                <>
                  <ButtonBase onClick={() => hUI.showMobileSidebar()} sx={{ paddingLeft: 1, paddingRight: 1 }}>
                    <MenuIcon style={{ fontSize: 32 }} />
                  </ButtonBase>

                  <Divider orientation="vertical" flexItem />
                </>
              )}
            </Box>

            <Box sx={{ margin: "auto", marginRight: "30px", fontSize: "15px" }}>
              <AnnounceKit
                widget="https://updates.demand-iq.com/widgets/v2/2lrLRS"
                widgetStyle={{ width: "20px", height: "auto", paddingLeft: "3px" }}
                user={{
                  isStella: true,
                  ...authState.user,
                }}
              >
                <span>What's new?</span>
              </AnnounceKit>
            </Box>

            <UserMenu />
          </AppBar>

          <Box sx={{ height: "calc(100% - 64px)" }}>
            <Scrollbars autoHide>{children}</Scrollbars>
          </Box>
        </Box>
      </Box>

      <Backdrop
        sx={{
          backgroundColor: "#000c",
          color: "#fff",
          zIndex: 9999,
        }}
        open={loading !== ""}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="inherit" />

          <Box mt={2}>{loading}</Box>
        </Box>
      </Backdrop>

      <Snackbar
        open={hAlert.open}
        autoHideDuration={3000}
        onClose={hAlert.close}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert variant="filled" severity={hAlert.success ? "success" : "error"} sx={{ width: "100%" }}>
          {hAlert.message}
        </Alert>
      </Snackbar>
      <Script url="https://cdn.nolt.io/widgets.js" onLoad={handleScriptLoad} />
    </Div100vh>
  );
};

export default Layout2;
